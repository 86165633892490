<template>
	<section v-if="productPage">
		<h2 v-if="viewHistory.length">Du tittade nyligen på</h2>
		<h2 v-else-if="campaign">{{ campaign.name }}</h2>
		<product-tip-slider
			:products="products"
			:showBuyBtn="false"
			:loop="false"
			:showStock="false"
			showProgressBar
			:compact="compactCarousel"
			parentElement="product"
		/>
	</section>
	<section v-else :class="topPanel ? 'top-panel-recent' : 'right-col-panel'">
		<h2 v-if="viewHistory.length && topPanel" class="level-two-heading">Senast visade</h2>
		<header v-else-if="! topPanel" class="right-col-panel-heading">
			<h2 v-if="viewHistory.length" class="level-four-heading">Senast visade</h2>
			<h2 v-else-if="campaign" class="level-four-heading">{{ campaign.name }}</h2>
		</header>
		<product-list-recent-item v-for="(product, index) in products" :key="product.id" :product="product" @click.native="clicked(product, index)" :showRemoveButton="viewHistory.length > 0" />
	</section>
</template>
<script>
import { mapGetters } from 'vuex';
import store from 'datastore';
import { trackImpression } from 'gtm';

export default {
	props: {
		backupProducts: {
			type: Array,
			required: false,
			default: () => [],
		},
		topPanel: {
			type: Boolean,
			default: false,
			required: false,
		},
		campaign: {
			type: Object,
			default: () => {},
			required: false,
		},
		compactCarousel: {
			type: Boolean,
			default: false,
			required: false,
		},
		productPage: {
			type: Boolean,
			default: false,
			required: false,
		},
	},
	store,
	data () {
		return {
			recentProducts: [],
			topView: [],
			currentRoute: null,
		};
	},
	methods: {
		clicked (product, index) {
			trackImpression([product], 'Senast visade', { event: 'select_item', position: index });
		},
		shortRecents () {
			this.topView = this.viewHistory;

			this.topView.splice(3);
		},
	},
	computed: {
		products () {
			return this.viewHistory.length ? this.viewHistory : this.backupProducts;
		},
		...mapGetters({
			viewHistory: 'viewHistory/getHistory',
			isLoggedIn: 'user/isLoggedIn',
		}),
	},
	mounted () {
		store.dispatch('viewHistory/loadHistory');
		this.currentRoute = this.$route.name;
	},
	watch: {
		viewHistory () {
			trackImpression(this.viewHistory, 'Senast visade');
			//@TODO gör en snyggare lösning
			if (this.topPanel && this.topView[0] !== this.viewHistory[0]) {
				this.shortRecents();
			}
		},
	},
};
</script>
