<template>
	<v-touch @swipeleft="moveRight" @swiperight="moveLeft" class="product-tip-wrap">
		<icon-button v-if="showLeftArrow" variant="left" @click="moveLeft" />
		<div class="product-tip-slider-anchor" :style="[anchorStyle, centerStyle]">
			<div
				v-for="(product, index) in loopedProducts" :key="componentKeys[index]+index"
				class="product-tip-slider-anchor-wrap"
				:style="productStyle"
			>
				<product-grid-item
					:showStock="showStock"
					:product="product"
					:showBuyBtn="showBuyBtn"
					:hidePass="true"
					:showBorder="showProductBorder"
					:showRating="showRating"
					:showNrReviews="showNrReviews"
					:parentElement="parentElement"
					:showSplash="showSplash"
					@handleLinkClick="gotClicked(product, index)"
				/>
				<flash-sale v-if="showFlashSales && (product.price.flashSale && product.price.nearlyOver)" :productPrice="product.price" compact showWhenSaleOver />
				<template v-else-if="showReviews">
					<top-review v-if="product.reviewHighlights && product.reviewHighlights[0].text" :review="product.reviewHighlights[0]" :showRating="showRating" :product="product" />
				</template>
			</div>
			<a-link
				v-if="showFlashSales && showFlashSalesBanner"
				:to="topListLink"
				class="flash-sales-banner"
			>
				<ximg
					class="cover"
					src="/img/flashsale/flashsale_flash.svg?cache=none"
					ariaLabel="flashsale blixt"
					role="img"
				/>
			</a-link>
			<div class="clearfix" />
		</div>
		<div v-if="showProgressBar && maxPage > 1">
			<progress-bar text="" :value="barSize" :spaceVal="spaceVal" class="mb-0" style="height: 5px" />
			<div class="relative product-tip-slider-progress-text">Sida {{ atPage }} av {{ maxPage }}</div>
		</div>
		<icon-button v-if="showRightArrow" variant="right" @click="moveRight" />
	</v-touch>
</template>
<script>
import mq from 'mediaQuery';
import { trackImpression } from 'gtm';
import { mapGetters } from 'vuex';
import { isDesktop } from 'mediaQuery';

export default {
	props: {
		loop: {
			type: Boolean,
			default: true,
		},
		products: {
			type: Array,
			required: true,
		},
		compact: {
			type: Boolean,
			default: false,
		},
		showBuyBtn: {
			type: Boolean,
			default: false,
		},
		showProgressBar: {
			type: Boolean,
			default: false,
		},
		showProductBorder: {
			type: Boolean,
			default: true,
		},
		showStock: {
			type: Boolean,
			default: true,
		},
		title: {
			type: String,
			default: '',
			required: false,
		},
		link: {
			type: String,
			default: '',
			required: false,
		},
		showFlashSales: {
			type: Boolean,
			default: false,
		},
		showReviews: {
			type: Boolean,
			default: false,
		},
		showNrReviews: {
			type: Boolean,
			default: false,
		},
		showRating: {
			type: Boolean,
			default: false,
		},
		toplist: {
			type: Object,
			required: false,
			default: () => {},
		},
		topListLink: {
			type: [String, Object],
			required: false,
		},
		parentElement: {
			type: String,
			default: '',
		},
		showSplash: {
			type: Boolean,
			default: false,
		}
	},
	data () {
		return {
			index: 0,
			isAnimating: false,
			animationTime: 0.2,
			internalProducts: this.products.slice(),
			productToSendForImpression: [],
			impressionsAlreadySentProductIds: [],
			currentPage: 1,
		};
	},
	mounted () {
		this.productToSendForImpression = this.products.slice(0, 4);
		this.productToSendForImpression.forEach(p => {
			this.impressionsAlreadySentProductIds.push(p.id);
		});
		this.sendTrackingEvent();
	},
	methods: {
		animate () {
			this.isAnimating = true;
			setTimeout(() => {
				// Flytta produkterna i arrayen och återställ index till 0
				this.isAnimating = false;
				const prods = this.internalProducts;
				if (this.loop) {
					if (this.index < 0) {
						const len = prods.length;
						this.internalProducts = prods.slice(this.index + len)
							.concat(prods.slice(0, this.index + len));
					} else if (this.index > 0) {
						this.internalProducts = prods.slice(this.index)
							.concat(prods.slice(0, this.index));
					}
					this.index = 0;
				}
			}, Math.ceil(1000 * this.animationTime));
		},
		moveLeft () {
			if (this.isAnimating || ! this.isOverflowing || ! this.showLeftArrow) {
				return;
			}
			this.animate();

			this.currentPage--;
			this.index -= this.possiblyVisibleElementCount;

			if (! this.loop && this.index < 0) {
				this.index = 0;
			}
		},
		moveRight () {
			const elementCount = this.possiblyVisibleElementCount;

			if (this.isAnimating || ! this.isOverflowing || ! this.showRightArrow) {
				return;
			}
			this.animate();

			this.currentPage++;
			this.index += this.possiblyVisibleElementCount;

			if (! this.loop && this.index > this.products.length - elementCount) {
				this.index = this.products.length - elementCount;
			}

			const productsInArray = this.impressionsAlreadySentProductIds.length;
			const productsToBeAdded = this.products.slice(productsInArray, (productsInArray + 4) > this.products.length ? this.products.length : (productsInArray + 4));
			this.productToSendForImpression = [];
			productsToBeAdded.forEach(p => {
				if (! this.impressionsAlreadySentProductIds.includes(p.id)) {
					this.productToSendForImpression.push(p);
					this.impressionsAlreadySentProductIds.push(p.id);
				}
			});

			if (this.productToSendForImpression.length > 0) {
				this.sendTrackingEvent();
			}
		},
		sendTrackingEvent() {
			if (this.toplist) {
				const data = {
					campaignId: this.toplist.campaignId,
					rating: true,
					showTopListReview: this.showReviews,
				}
				trackImpression(this.productToSendForImpression, this.toplist ? this.toplist.name : 'n/a', data);
			}
		},
		gotClicked (product, index) {
			trackImpression([product], this.toplist ? this.toplist.name : this.parentElement, {
				rating: true,
				stock: false,
				event: 'select_item',
				position: index
			});
			if (product?.ticket) {
				const data = {
					touchpoint: isDesktop() ? 'DESKTOP' : 'MOBILE'
				};
				waja.get('productdiscovery/click/' + product.ticket).data(data).go();
			}
		},
	},
	computed: {
		showLeftArrow () {
			if (! this.loop && this.index <= 0) {
				return false;
			}
			return this.isOverflowing;
		},
		showRightArrow () {
			const elementCount = this.possiblyVisibleElementCount;

			if (! this.loop && this.index >= this.products.length - elementCount) {
				return false;
			}
			return this.isOverflowing;
		},
		componentKeys () { // Om vi inte sätter keys på elementen så kommer de behöva renderas om istället för återanvändas när vi ändrar ordning i listan
			const keys = [];
			const usedProducts = [];
			for (const product of this.loopedProducts) {
				let key = product.id + '';
				if (usedProducts.includes(product.id)) {
					key += '-2';
				} else {
					usedProducts.push(product.id);
				}
				keys.push(key);
			}
			return keys;
		},
		loopedProducts () {
			if (! this.isOverflowing || ! this.loop) {
				return this.internalProducts;
			}
			const loopedProducts = this.internalProducts.slice(this.internalProducts.length - 2, this.internalProducts.length)
			  .concat(this.internalProducts,this.internalProducts.slice(0, 1));
			return loopedProducts;
		},
		productStyle () {
			return {
				minWidth: this.elementWidth + '%',
				maxWidth: this.elementWidth + '%',
			};
		},
		navStyle () {
			if (this.desktopCheckout) {
				return {
					width: '13%',
				};
			}
			return {
				width: this.gutterWidth * .5 + '%',
			};
		},
		isOverflowing () {
			return this.possiblyVisibleElementCount < this.products.length;
		},
		gutterWidth () {
			return this.elementWidth + (100 % this.elementWidth);
		},
		possiblyVisibleElementCount () {
			return Math.floor(100 / this.elementWidth);
		},
		visibleElementCount () {
			return Math.floor((100 - this.gutterWidth) / this.elementWidth);
		},
		elementWidth () {
			if (mq.current < mq.md) {
				return 50;
			}
			if (this.compact) {
				return 50;
			}
			if (mq.current < mq.lg) {
				return 33;
			}
			return 25;
		},
		anchorStyle () {
			if (! this.isOverflowing) {
				return null;
			}
			const translation = -(this.elementWidth * (this.index + (this.loop ? 1 : -1)) + this.elementWidth);
			const style = {
				transform: 'translateX(' + (translation) + '%)',
			};
			if (this.isAnimating) {
				style.transition = this.animationTime + 's ease-out transform';
			}
			return style;
		},
		// Centers products in carousel in the cart
		centerStyle () {
			const center = {};
			if (this.desktopCheckout) {
				center.textAlign = 'center';
			}
			return center;
		},
		desktopCheckout () {
			return this.compact && mq.current >= mq.md;
		},
		barSize() {
			return Math.ceil((1 / this.maxPage)*100)/100;
		},
		stepSize () {
			return this.possiblyVisibleElementCount;
		},
		maxPage () {
			return Math.ceil(this.products.length/this.stepSize);
		},
		atPage () {
			return this.maxPage <= 1 ? this.maxPage : this.currentPage;
		},
		// Adds a spacer before the blue pagnation bar
		spaceVal () {
			return Math.floor(((1 / this.maxPage) * (this.atPage - 1))*100)/100;
		},
		showFlashSalesBanner () {
			if (mq.current < mq.md || this.compact) {
				return this.loopedProducts.length < 2;
			} else if (mq.current < mq.lg) {
				return this.loopedProducts.length < 3;
			} else {
				return this.loopedProducts.length < 4;
			}
		},
		...mapGetters({
			isLoggedIn: 'user/isLoggedIn',
		}),
	},
	watch: {
		products (products) {
			this.internalProducts = products.slice();
			this.index = 0;
			this.isAnimating = false;
		},
		possiblyVisibleElementCount () {
			if (this.currentPage > this.maxPage) {
				this.currentPage = this.maxPage;
				this.index = this.possiblyVisibleElementCount * (this.maxPage - 1);
			} else {
				this.index = this.possiblyVisibleElementCount * (this.currentPage - 1);
			}
		},
	},
};
</script>
