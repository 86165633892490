<template>
	<div class="product-browser">
		<transition v-if="! googleBot" name="fast-fade">
			<toggle-button
				v-if="(page > 1 || disableAutoScroll) && hasMorePages"
				:class="['autoscroll', {'hide-margin': page === 1}]"
				:text="t(disableAutoScroll ? 'AUTOSCROLL_OFF' : 'AUTOSCROLL_ON')"
				:value="t(disableAutoScroll ? 'AUTOSCROLL_OFF' : 'AUTOSCROLL_ON')"
				:activeValue="t('AUTOSCROLL_ON')"
				size="tiny"
				@click="disableAutoScroll = ! disableAutoScroll; clickedOnShowMoreButton = true;"
			/>
		</transition>
		<div class="list-settings">
			<template v-if="! useCompactLayout">
				<progress-bar
					class="progress-result-counter"
					:value="filteredProductCount / totalProductCount"
					:text="(e) => 'Visar ' + formatNumber(filteredProductCount) + ' av ' + ts('RESULT_COUNT', formatNumber(totalProductCount))"
				/>
			</template>
			<div v-if="! useCompactLayout" class="toggle-group">
				<toggle-button
					v-model="productListMode"
					:text="tc('LIST_VIEW')"
					:activeValue="LIST_MODE_STANDARD"
					icon="list_view"
					noText
				/>
				<toggle-button
					v-model="productListMode"
					:text="tc('LIST_COMPACT_VIEW')"
					:activeValue="LIST_MODE_COMPACT"
					icon="compact_view"
					noText
				/>
				<toggle-button
					v-model="productListMode"
					:text="tc('GRID_VIEW')"
					:activeValue="LIST_MODE_GRID"
					icon="grid_view"
					noText
				/>
			</div>
			<toggle-button
				v-if="! useCompactLayout"
				v-show="filterEnabled"
				class="active"
				text="Filtrera"
				value="filtrera"
				icon="list_filter"
				:counter="filterCount"
				hideEmptyCounter
				@click="toggleFilter"
			/>
			<dropdown-select
				v-if="showSort"
				v-model="sortingOption"
				name="product-sorting"
				:formatter="ts"
				:items="sortingOptions"
				prefix="SORT_BY"
				:open.sync="dropdownOpen"
			/>
		</div>

		<!-- Different failed search results -->
		<div v-if="spellingSuggestions && spellingSuggestions.length" class="failed-search">
			<span>Menade du:</span>
			<router-link
				v-for="spellingSuggestion in spellingSuggestions"
				class="ml-5 d-inline-block"
				:key="spellingSuggestion"
				:to="getRelatedQueryRoute({ searchString: spellingSuggestion, })"
				@click.native="setOrigin('DID_YOU_MEAN')"
			>
				{{ spellingSuggestion }}
			</router-link>
		</div>
		<div v-else-if="relatedQueries && relatedQueries.length" class="failed-search">
			<span>Andra sökte på:</span>
			<router-link
				v-for="query in relatedQueries"
				class="ml-5 d-inline-block"
				:key="query.searchString"
				:to="getRelatedQueryRoute(query)"
			>
				{{ query.searchString }}
			</router-link>
		</div>
		<div v-if="isUncertainResult" class="failed-search">Din sökning gav tyvärr inga träffar, men du kanske är intresserad av dessa produkter</div>
		<div class="failed-search" v-if="hasNoResults && isSearch">{{ t('SEARCH.NO_RESULTS') }}</div>

		<div v-if="showProducts && ! googleBot" class="mt-4">
			<product-list-page
				v-for="page in maxReachedPage"
				ref="pages"
				:key="page"
				:categoryIcon="category ? category.icon : ''"
				:forceShowRelease="forceShowRelease"
				:lastPage="! hasMorePages && page === maxReachedPage + 1"
				:page="page"
				:pageData="pageData[page+'']"
				:pageHeight="pageHeight"
				:pass="pass"
				:productListMode="productListMode"
				:productsPerPage="productsPerPage"
				:ready="!! currentQuery && componentReady"
				:pageType="pageType"
				@request-load="loadPage(page)"
				@recalculatedHeight="onHeightUpdated"
			/>
			<div v-if="hasMorePages && ! loading" class="m-5">
				<div>
					<span class="secondary">Visar {{ shownProducts }} av {{ filteredProductCount }} produkter</span>
					<a class="pull-right" @click="scrollToTop">Gå till toppen<span class="hidden-xs"> av sidan</span></a>
				</div>
				<auto-scroller
					:viewportMargin="400"
					:loading="loading"
					:trigger="increasePage"
					:disableAutoScroll="disableAutoScroll"
				>
					<toggle-button
						v-if="! useCompactLayout"
						text="Visa fler produkter"
						value="Visa fler produkter"
						size="large"
						icon="chevron_down"
						iconRightPlacement
						stretch
						@click.native="increasePage(true)"
					/>
				</auto-scroller>
			</div>
			<product-list-progress :browser="this" :width="width" />
		</div>
		<div v-else-if="googleBot">
			<!-- Paginator buttons -->
			<paginator-buttons v-if="pageCount > 1" v-model="page" class="my-5 px-4" :nrPages="pageCount" ref="paginator" />
			<product-list-page
				:ready="!! currentQuery && componentReady"
				:page="page"
				:pageData="pageData[page+'']"
				:productListMode="productListMode"
				:pageHeight="pageHeight"
				ref="pages"
				:lastPage="! hasMorePages && page === maxReachedPage + 1"
				:forceShowRelease="forceShowRelease"
				:pass="pass"
				:productsPerPage="productsPerPage"
				:pageType="pageType"
				@request-load="loadPage(page)"
				@recalculatedHeight="onHeightUpdated"
			/>
		</div>
		<div v-if="relatedProducts && relatedProducts.length" class="mt-5">
			<div class="clearfix"></div>
			<h2 class="px-4">Du kanske även är intresserad av dessa produkter</h2>
			<product-tip-slider :parentElement="pageType" :products="relatedProducts" :showBuyBtn="false" :loop="false" :showProgressBar="true"/>
		</div>
	</div>
</template>

<script>
import { throttle, isEqual, debounce } from 'lodash';
import localSessionStorage from 'localSessionStorage';
import waja from 'waja';
import bus from 'eventbus';
import mq, { isDesktop } from 'mediaQuery';
import router from 'router';
import store from 'datastore';
import { t } from 'datastore/i18n';
import tagHandler from 'tagHandler';
import { mapState, mapGetters } from 'vuex';
import businessVariables from 'businessVariables';
import { googleBot, sectionIds, defaultProductListMode } from 'staticData';
import productListProgress from './product-list-progress';
import { formatNumber, scrollTo, scrollToY, convertToCdnUrl, getExtraProductData, error, sendGoogleOptimizeEvent } from 'utils';
import { trackEvent, trackProductFilter } from 'gtm';

export default {
	store,
	props: {
		banner: {},
		category: {},
		campaign: {},
		manufacturer: {},
		manufacturerPage: {},
		toplist: {},
		article: {},
		url: {
			type: String,
			default: null,
		},
		alwaysResetResultCount: {
			default: false,
		},
		noSort: {
			default: false,
		},
		layoutOptions: {
			type: Object,
			default: () => {},
		},
		/**
		 * Controls whether the browser may update the url query when the page
		 * is changed.
		 */
		canSetPageQuery: {
			type: Boolean,
			default: true,
		},
		passFallback: {},
		/**
		 * Controls whether the browser sets the canonical link. This is enabled
		 * by default to allow for better SEO as developers don't have to think
		 * about this when implementing new product lists.
		 */
		canSetCanonical: {
			type: Boolean,
			default: true,
		},
		isSearch: { // Is this a search result? Determines what to show if there are no results
			type: Boolean,
			default: false,
		},
		onSearchPage: {
			type: Boolean,
			default: false,
		},
		searchEngine: {
			type: String,
			default: null,
		},
		sortFromSettings: {
			type: String,
			default: null,
		},
		/** Previously the parent called reset with $refs,
		 * but since that now causes an unsteady reset,
		 * and only the parent knows the true resetData,
		 * we pass it down instead
		 */
		resetData: {
			type: Array,
			default() {
				return []
			},
		},
	},
	components: {
		productListProgress,
	},
	data () {
		return {
			mq,
			googleBot,
			pageData: {},
			page: 1,
			maxReachedPage: ~~this.$route.query.page || 1,
			sortingOption: null,
			availableSortingOptions: [],
			disableAutoScroll: false,
			overridePageHeight: null,
			LIST_MODE_STANDARD: 1,
			LIST_MODE_GRID: 2,
			LIST_MODE_COMPACT: 3,
			hasDeterminedPageHeight: false,
			width: 0,
			hasJustReset: false,
			defaultLayoutOptions: {
				// Use x padding for the browser wrapper div.
				usePadding: true,
				// Compact layout for mobile devices.
				compactLayout: false,
				// The compact layout will be deactivated if the number of
				// products is > this threshold.
				compactThreshold: 10,
				// Indicates whether the filters should be reset when
				// resizing.
				resetFiltersOnResize: false,
			},
			relatedProducts: [],
			isUncertainResult: false,
			relatedQueries: null,
			spellingSuggestions: [],
			clickedOnShowMoreButton: false,
			dropdownOpen: false,
			initialFiltersUpdated: false,
			// Checks if component is mounted, to disable product-list-page to request load
			componentReady: false,
			autoCorrectQuery: null,
			// To disable double loading with typo query + autocorrected on first load
			autoCorrectJustSet: false,
		};
	},
	computed: {
		hasNoResults () {
			return this.filteredProductCount === 0 && ! this.loading;
		},
		shownProducts () {
			return Math.min(this.filteredProductCount, this.page * this.productsPerPage);
		},
		pass () {
			return this.$route.query.pass || this.passFallback;
		},
		computedLayout () {
			return Object.assign(this.defaultLayoutOptions, this.layoutOptions);
		},
		sortingOptions () {
			// @TODO refactor. kan vara en array med strängar bara.
			const opts = [];

			if (this.availableSortingOptions?.length) {
				opts.push(...this.availableSortingOptions);
			}

			return opts.map(o => {
				if (Array.isArray(o)) {
					return {
						value: o[0],
						label: o[1],
						sort: o[0][o[0].length-1] == 'a' ? 'SORT.asc' : 'SORT.desc',
					};
				}
				let sort = '';
				let value = o;
				let label = 'SORT.' + value;
				if (o.indexOf('Asc') > -1) {
					value = o;
					label = 'SORT.' + o.replace('Asc', '');;
					sort = 'SORT.' + o;
				} else if (o.indexOf('Desc') > -1) {
					value = o;
					label = 'SORT.' + o.replace('Desc', '');;
					sort = 'SORT.' + o;
				} else if (o === 'highestDiscountPercentStrict') {
					value = o.replace('Strict', '');
					label = 'SORT.' + o.replace('Strict', '');
					sort = 'SORT.' + o;
				} else if (o === 'highestDiscountStrict') {
					value = o.replace('Strict', '');
					label = 'SORT.' + o.replace('Strict', '');
					sort = 'SORT.' + o;
				}
				return {
					value: value,
					label: label,
					sort: sort,
				};
			});
		},
		forceShowRelease () {
			return [
				'latestReleased',
			].indexOf(this.sortingOption) >= 0;
		},
		showSort () {
			// Temp hide for toplists
			if (this.forcedSort || !! this.toplist) {
				return false;
			}
			return ! this.noSort;
		},
		forcedSort () {
			if (this.category && this.category.options.forceSort) {
				return this.category.options.forceSort[0];
			}
			return false;
		},
		showProducts () {
			return this.initialFilters.length > 0 || (this.currentQuery && this.currentQuery?.filters.length > 0);
		},
		filterCount () {
			return this.enabledFilters.length;
		},
		useCompactLayout () {
			const useCompact = this.computedLayout.compactLayout &&
				this.totalProductCount <= this.computedLayout.compactThreshold;

			// Temp hide for toplists
			if (!! this.toplist) {
				this.setFiltersEnabled(false);
			} else if (! useCompact !== this.filterEnabled) {
				this.setFiltersEnabled(! useCompact);
			}

			return useCompact && mq.current < mq.sm;
		},
		throttledOnScroll () {
			return throttle(this.onScroll, 250);
		},
		pageHeight () {
			if (this.overridePageHeight) {
				return this.overridePageHeight;
			}
			switch (this.productListMode) {
				case this.LIST_MODE_GRID:
					// 301 = height grid row title two
					// 284 = height grid row without title two
					const lineHeight = this.showTitleTwo ? 301 : 284;
					return (this.productsPerPage / 3) * lineHeight;
				case this.LIST_MODE_COMPACT:
					// 64 = height of a compact row
					return this.productsPerPage * 64;
				default:
					// 105 = height of normal row
					return this.productsPerPage * 105;
			}
		},
		pageCount () {
			// Total number of pages
			return Math.max(Math.ceil(this.filteredProductCount / this.productsPerPage));
		},
		hasMorePages () {
			return this.maxReachedPage < this.pageCount;
		},
		productCount () {
			let count = 0;
			for (const p in this.pageData) {
				const page = this.pageData[p];
				if (page.products) {
					count += page.products.length;
				}
			}
			return count;
		},
		loading () {
			for (const page in this.pageData) {
				if (this.pageData[page].products === null) {
					return true;
				}
			}
			return false;
		},
		productListMode: {
			get () {
				switch (this.userSettings.product_list_mode) {
					case this.LIST_MODE_GRID:
					case this.LIST_MODE_COMPACT:
					case this.LIST_MODE_STANDARD:
						return this.userSettings.product_list_mode;
					default:
						return defaultProductListMode;
				}
			},
			set (value) {
				let listMode = 'default';
				switch (value) {
					case this.LIST_MODE_COMPACT:
						listMode = 'Kompakt listvy';
						break;
					case this.LIST_MODE_GRID:
						listMode = 'Rutnätsvy';
						break;
					case this.LIST_MODE_STANDARD:
						listMode = 'Listvy';
						break;
					default:
						break;
				}
				trackEvent('gaEvent', 'Interactions', 'Toggle Click', listMode);
				this.$store.commit('user/settings', {
					key: 'product_list_mode',
					value: value,
				});
			},
		},
		productsPerPage () {
			return ! this.toplist ? 24 : 12;
		},
		pageType () {
			let pageType = '';
			if (this.campaign) {
				pageType = 'campaign';
			} else if (this.article) {
				pageType = 'article';
			} else if (this.category) {
				pageType = 'category';
			} else if (this.manufacturer || this.manufacturerPage) {
				pageType = 'manufacturer';
			}
			return pageType;
		},
		...mapState({
			navStatus: state => state.navStatus,
			userSettings: state => state.user.settings,
			showTitleTwo: state => state.user.showTitleTwo,
			origin: state => state.productFilters.origin,
			attributes: state => state.productFilters.attributes,
			currentQuery: state => state.productFilters.currentQuery,
			filterEnabled: state => state.productFilters.filterEnabled,
			enabledFilters: state => state.productFilters.enabledFilters,
			initialFilters: state => state.productFilters.initialFilters,
			searchCategories: state => state.productFilters.searchCategories,
			totalProductCount: state => state.productFilters.totalProductCount,
			filteredProductCount: state => state.productFilters.filteredProductCount,
		}),
		...mapGetters({
			adminPanel: 'user/isAdminPanelShowing',
		}),
	},
	methods: {
		convertToCdnUrl,
		formatNumber,
		t,
		ts: t.prefix('SUPERSEARCH'),
		tc: t.prefix('CATEGORY'),
		setFiltersEnabled: debounce(function (enabled) {
			this.$store.commit('productFilters/setFilterEnabled', enabled);
		}, 200),
		resetDisableAutoScroll() {
			this.disableAutoScroll = true;
			this.clickedOnShowMoreButton = false;
		},
		setDisableAutoScroll() {
			// @TODO vi har gjort om en hel del här, så checka att det fortf känns relevant.
			// Är främst söksidan som har autoscroll disabled
			if (this.clickedOnShowMoreButton) {
				this.disableAutoScroll = false;
			} else if (this.filteredProductCount <= 46 && ! this.clickedOnShowMoreButton) {
				this.disableAutoScroll = false;
			} else {
				this.disableAutoScroll = true;
			}
		},
		scrollToTop () {
			scrollToY(0, 0.5);
		},
		highlightFilterMenu () {
			scrollTo('#product-list-filter-container', false);

			const filterBoxes = document.querySelectorAll('#product-list-filter-container .filter-contents');
			for (const box of filterBoxes) {
				box.classList.add('_flash');
			}
			setTimeout(() => {
				for (const box of filterBoxes) {
					box.classList.remove('_flash');
				}
			}, 1500);
		},
		onHeightUpdated (height, isFullPage) {
			this.$nextTick(() => {
				bus.$emit('saved-scroll.apply');
			});
			if (isFullPage) {
				this.overridePageHeight = height;
				this.hasDeterminedPageHeight = true;
			}
			if (! this.width) {
				this.updateWidth();
			}
		},
		openFilterMenu () {
			bus.$emit('supersearch.open');
		},
		toggleFilter () {
			if(mq.current < mq.lg) {
				this.openFilterMenu();
				trackProductFilter('open_filter_options');
			} else {
				this.highlightFilterMenu();
				trackProductFilter('highlight_filter_options');
			}
		},
		onScroll () {
			if (! this.hasDeterminedPageHeight) {
				return;
			}
			let minDist = Number.POSITIVE_INFINITY;
			let closestPage = null;
			if (this.$refs.pages) {
				const scrollY = window.pageYOffset || document.documentElement.scrollTop;
				const viewportCenter = scrollY + window.innerHeight * .5;
				for (const page of this.$refs.pages) {
					const dist = Math.abs(page.centerY - viewportCenter);
					if (dist < minDist) {
						minDist = dist;
						closestPage = page;
					}
				}
			}
			if (closestPage) {
				this.page = closestPage.page;
			}
		},
		increasePage (clickedOnShowMoreButton = false) {
			// Checking if current page is done loading before requesting another page
			if (this.hasMorePages && ! this.$refs.pages[this.page - 1].loading) {
				this.maxReachedPage++;
				if (clickedOnShowMoreButton) {
					this.clickedOnShowMoreButton = true;
				}
				if (this.onSearchPage) {
					this.setDisableAutoScroll();
				}
			}
		},
		reset (initialFilters, parseUrlQuery = false, resetSort = true, forceLoad = false) {
			this.hasJustReset = true;
			this.hasDeterminedPageHeight = false;
			this.overridePageHeight = null;
			this.pageData = {};

			this.autoCorrectQuery = null;
			this.autoCorrectJustSet = false;

			this.initialFiltersUpdated = true;
			this.$store.commit('productFilters/setInitialFilters', initialFilters);

			// Checks for stock filters in session
			let updatedEnabledFilters = [];
			const stockValues = localSessionStorage.get('stockFilters');
			if (stockValues) {
				try {
					const toApply = JSON.parse(stockValues);
					updatedEnabledFilters = [{
						type: 'attributes',
						value: toApply.id + '-1-' + toApply.values.join('~'),
					}];
				} catch(e) {}
			}
			this.$store.commit('productFilters/setEnabledFilters', updatedEnabledFilters);

			if (parseUrlQuery && this.$route.query.page) {
				console.log('page read from query');
				this.page = ~~this.$route.query.page;

				if (! googleBot) {
					// fulhack för att lämna utrymme nedåt för scroll saving
					this.maxReachedPage = this.page + 1;
					// @TODO förläng istället sidan genom att kolla hur lång den behöver vara
					// för att klienten ska kunna scrolla till sparad position
					// (tills "saved-scroll.apply"-eventet skickas)
				} else {
					this.maxReachedPage = this.page;
				}
			} else {
				this.page = 1;
				this.maxReachedPage = 1;
			}

			if (resetSort) {
				this.sortingOption = null;
			} else if (parseUrlQuery && this.$route.query.sort) {
				this.sortingOption = this.$route.query.sort;
			} else if (this.sortFromSettings) {
				this.sortingOption = this.sortFromSettings;
			}

			this.$store.commit('productFilters/setSortingOption', this.sortingOption);
			this.$store.commit('productFilters/setTotalProductCount', 0);
			this.$store.commit('productFilters/setFilteredProductCount', 0);
			this.$store.commit('productFilters/setSearchCategories', null);
			this.$store.commit('productFilters/setSearchTree', null);
			this.$store.commit('productFilters/setAttributes', null);


			// If the compact layout is used, then don't want to parse the url query
			// (as it could lead to 0 products showing in the compact layout)
			// with no way of removing the filters.
			if (this.useCompactLayout) {
				parseUrlQuery = false;
			}

			if (parseUrlQuery) {
				this.$store.dispatch('productFilters/parseUrlQuery', { query: this.$route.query });
			} else {
				this.$store.dispatch('productFilters/updateCurrentQuery', {});
			}

			// This is used in some admin tools, so we save it until we have worked those out
			// 0 and 9999999 is previous min/max prices so just send absolute min/max for now
			bus.$emit('supersearch.reset', initialFilters, parseUrlQuery, 0, 999999, forceLoad);
		},
		loadPage (page) {
			if (! this.currentQuery) { // There's no query defined yet
				this.$emit('loaded');
				console.error('product-browser: no query defined');
				return;
			}
			if (this.pageData[page + '']) { // This page is already loading/loaded
				console.error('product-browser: this page is already loaded/loading');
				if (this.pageData[page + ''].products) {
					// Make sure that the products have rendered, so wait to the next tick.
					Vue.nextTick(() => {
						/** Silly little double check because the check before
						 * sometimes checks previously loaded products, which nextTick
						 * realise doesn't exist anymore
						*/
						if (this.pageData[page + '']?.products){
							this.$emit('loaded');
						}
					});
				}
				return;
			}

			const pageData = {
				products: null,
			};

			this.$set(this.pageData, page + '', pageData);

			const filters = [];
			let hasPrioritizedCategory = false;
			let hasSearchTreeCategory = false;
			let spliceIndex = 0;

			for (let i = 0; i < this.enabledFilters.length; i++) {
				filters.push(this.enabledFilters[i].value);

				/** Sometimes this is undefined, so specifically has to check
				 * if it's false, otherwise it will splice other filters that
				 * doesn't have this property
				 */
				if (this.enabledFilters[i]?.prioritized === false) {
					hasSearchTreeCategory = true;
					spliceIndex = i;
				}

				if (this.enabledFilters[i]?.prioritized) {
					hasPrioritizedCategory = true;
				}
			}

			/** We can't both send main category and prioritized filter
			 * to backend, so if there is a prioritized category-filter
			 * we need to splice the first one
			 */
			if (hasPrioritizedCategory && hasSearchTreeCategory) {
				filters.splice(spliceIndex, 1);
			}

			const data = {
				page: page,
				filters: filters,
				touchpoint: isDesktop() ? 'DESKTOP' : 'MOBILE',
				// This enables backend to only get the totalProductCount once per page
				totalProductCountSet: this.totalProductCount ? this.totalProductCount > 0 : (page > 1 && !! this.pageData[page - 1]) || false,
			}

			// Password protected prices
			if (this.pass) {
				data.pass = this.pass;
			}

			if (this.searchEngine) {
				data.engine = this.searchEngine;
			}
			if (this.initialFilters[0].type === 'search') {
				data.origin = this.origin ?? 'ORGANIC';
			}

			// Don't send sorting option on default option
			if (this.sortingOption && this.sortingOption !== this.availableSortingOptions[0]) {
				data.sortBy = this.sortingOption;
			}

			/** Checking if there is an autocorrected searchstring and
			 * makes sure to in that case update the url
			 */
			let updatedUrl = this.url;
			if (this.autoCorrectQuery && this.autoCorrectQuery !== this.initialFilters[0].value) {
				updatedUrl = this.initialFilters[0].type + '/' + this.autoCorrectQuery;
			}

			waja.get('productdiscovery/' + updatedUrl)
				.data(data).on('success', res => {
					// if (this.pageData[page + ''] !== pageData) {
					// 	console.log('a page load completed, but the page data identity has changed');
					// 	return;
					// }

					pageData.products = res.data.products;

					this.spellingSuggestions = res.data.relatedQueries || [];
					this.relatedProducts = res.data.relatedProducts || [];

					this.availableSortingOptions = res.data.sortOptions;
					if (! this.sortingOption || ! res.data.sortOptions.includes(this.sortingOption)) {
						this.sortingOption = res.data.sortOptions?.length ? res.data.sortOptions[0] : null;
					}

					if (res.data.query && this.initialFilters[0].value !== res.data.query && this.autoCorrectQuery !== res.data.query) {
						this.autoCorrectQuery = res.data.query;
						this.autoCorrectJustSet = true;
					}

					/** Since the backend is doing two different API-calls the first
					 * time we get a page, we don't want to overwrite the
					 * totalProductCount the following times when it returns
					 * as NULL to frontend
					 */
					if (! this.totalProductCount) {
						this.$store.commit('productFilters/setTotalProductCount', res.data.totalProductCount);
					}

					this.$store.commit('productFilters/setFilteredProductCount', res.data.filteredProductCount);
					this.$emit('update:totalProductCount', this.totalProductCount);

					/** On campaign pages there is a special chek if the left-col
					 * should be shown or not, and this data is vital for the
					 * timing of that update
					 */
					if (this.campaign?.id) {
						this.$emit('update:totalProductCountSet', true);
					}

					if ((this.isSearch || this.initialFilters[0].type === 'campaign' || this.initialFilters[0].type === 'memberDeals')
						&& ! isEqual(this.searchCategories, res.data.categories)) {
						if (! res.data.categories?.children?.length) {
							this.$store.commit('productFilters/setSearchCategories', null);
							this.$store.commit('productFilters/setSearchTree', null);

						} else {
							this.$store.commit('productFilters/setSearchCategories', res.data.categories);

							/** In some cases enabledFilters are read from url query, so to ensure the
							 * current search tree renders correctly and not from base level,
							 * we make sure to check if there is an active category-filter
							 */
							if (! this.enabledFilters.length) {
								this.$store.dispatch('productFilters/updateSearchTree', { categories: res.data.categories });
							} else {
								const existingSearchTreeFilter = this.enabledFilters.find(filter => filter.type === 'category-filter' && ! filter?.prioritized);
								if (! existingSearchTreeFilter) {
									this.$store.dispatch('productFilters/updateSearchTree', { categories: res.data.categories });
								} else {
									const searchTreeId = existingSearchTreeFilter.value.split('-').pop();
									this.$store.dispatch('productFilters/updateSearchTree', { currentId: searchTreeId });
								}
							}
						}

					}

					// Temp disable for toplists
					if (! this.toplist && ! isEqual(this.attributes, res.data.filters)) {
						this.$store.commit('productFilters/setAttributes', res.data.filters);
					}

					/** This dispatch determines which filters are expanded in product-list-filters.
					 * We only want to do this dispatch once for every new page, hence the check for initialFiltersUpdated
					 */
					if (this.initialFiltersUpdated) {
						this.$store.dispatch('productFilters/updateAttributeShow', { settings: null });
						this.initialFiltersUpdated = false;
					}

					// If we loaded an empty page at the end of the list, remove it
					if (page === this.maxReachedPage && this.maxReachedPage > this.pageCount && ! pageData.products.length) {
						this.maxReachedPage--;
					}

					getExtraProductData(pageData.products, this);

					// Wait for the products to render.
					Vue.nextTick(() => {
						if (tagHandler.get('link', 'prev') === undefined && tagHandler.get('link', 'next') === undefined) {
							// When product list has been loaded for the first time, then create prev and next links.
							this.updateSEO();
						}
						this.$emit('loaded', { products: pageData.products, page: page, productsPerPage: this.productsPerPage });
					});
				})
				.on('error', res => {
					this.$emit('loaded');
				}).go();
		},
		updatedQuery (query) {
			if (this.hasJustReset) {
				this.hasJustReset = false;
			} else if (this.page > 2) {
				scrollTo(0);
			}

			if (query?.sortBy) {
				this.sortingOption = query?.sortBy;
			}

			if (query?.autoCorrect) {
				this.autoCorrectQuery = query?.autoCorrect;
			}
			if (this.autoCorrectJustSet) {
				this.autoCorrectJustSet = false;
			} else {
				this.pageData = {};
			}
			this.maxReachedPage = this.page;
			this.relatedProducts = [];
			this.relatedQueries = [];
			this.spellingSuggestions = [];
			this.isUncertainResult = false;

			// When googleBot, the product list page needs to be loaded immediately, even though
			// the it might not be in the viewport.
			if (googleBot) {
				this.loadPage(this.page);
			} else {
				this.loadPage(1);
				// för att vi ska visa filter och köra saved-scroll.apply utan att kund behöver scrolla ned.
				// @TODO försök kolla om vi har en bättre lämpad sida att ladda för filter osv. scroll ska inte behöva sidladdning utan på sikt vill vi helt enkelt förstora sidan för att kunna applicera scrollpositionen
			}
		},
		updateWidth () {
			this.width = this.$el.getBoundingClientRect().width;
		},
		/**
		 * SEO: Creates prev and next links so bots can interpret the product list
		 * correctly.
		 *
		 * Also sets the canonical link to point to the current page without filters,
		 * to prevent bots from indexing filtered lists.
		 */
		updateSEO () {
			tagHandler.deleteTag('link', 'prev');
			tagHandler.deleteTag('link', 'next');

			const createRoute = page => {
				const route = {
					name: this.$route.name,
					params: {
						id: this.$route.params.id,
						name: this.$route.params.name,
					},
				};
				if (page > 1) {
					route.query = {
						page,
					};
				}
				return router.routeToUrl(router.route(route));
			};

			/*
			* Next and prev links for pagination.
			*/
			if (this.page > 1) {
				tagHandler.set({
					id: 'prev',
					type: 'link',
					attributes: {
						rel: 'prev',
						href: createRoute(this.page - 1),
					},
				});
			}
			if (this.page < this.pageCount) {
				tagHandler.set({
					id: 'next',
					type: 'link',
					attributes: {
						rel: 'next',
						href: createRoute(this.page + 1),
					},
				});
			} else if (this.page > this.pageCount) {
				if (googleBot) {
					// 404 if page number is too large
					error(404, 'Sidan kunde inte hittas')(this);
				}
			}

			if (this.canSetCanonical) {
				tagHandler.set({
					id: 'canonical',
					type: 'link',
					attributes: {
						rel: 'canonical',
						href: createRoute(this.page),
					},
				});
			}
		},
		getRelatedQueryRoute (query) {
			// @TODO what if we're not on the search page? (loop54 category listings)
			return this.$router.route({
				name: 'search',
				query: {
					searchString: query.searchString,
				},
			});
		},
		setOrigin (origin) {
			this.$store.commit('productFilters/setOrigin', origin);
		},
	},
	mounted () {
		this.reset(...this.resetData);
		this.componentReady = true;

		if (! googleBot) {
			window.addEventListener('scroll', this.throttledOnScroll);
		}

		// Used by admin so save for now
		bus.$on('supersearch.updated', this.updateQuery);
		this.$nextTick(() => {
			this.updateWidth();
		});
	},
	beforeDestroy () {
		this.componentReady = false;

		/** Makes sure there is no lingering data in state that
		 * creates bugs for when the component is re-mounted
		 */
		this.$store.dispatch('productFilters/resetWholeState');

		if (! googleBot) {
			window.removeEventListener('scroll', this.throttledOnScroll);
		}
		bus.$off('supersearch.updated', this.updateQuery);
	},
	watch: {
		'mq.pageWidth': function () {
			this.updateWidth();
		},
		page (page) {
			console.log('Page is now', page);
			if (this.$route.name === 'member.offers'
				&& navigator.userAgent.match(/SamsungBrowser/i)) {
				console.log('Samsung internet found, not updating query');
				return;
			}
			if (this.canSetPageQuery) {
				router.updateQuery({ page });
			}
			if (page && this.pageCount) {
				this.updateSEO();
			}
		},
		canSetPageQuery (canSet) {
			if (canSet) {
				// Update the page after page updating has been enabled again.
				router.updateQuery({ page: this.page });
			}
		},
		sortingOption (opt) {
			console.log('brows updated', opt);
			if (this.dropdownOpen) {
				trackProductFilter('apply_sort_by');
			}
			/** If default sorting is active, we don't wanna save it to state to
			 * prevent it getting saved to the url query
			 */
			this.$store.commit('productFilters/setSortingOption', opt === this.availableSortingOptions[0] ? null : opt);
		},
		useCompactLayout (usingNow, usingBefore) {
			// If the user resizes the window which triggers the compact layout,
			// then reset the filters (except the sorting option).
			if (usingNow && ! usingBefore && usingNow.resetFiltersOnResize) {
				console.log('USING COMPACT LAYOUT');
				this.reset(this.initialFilters, true, false);
			}
		},
		adminPanel (to) {
			if (to) {
				for (const page in this.pageData) {
					if (this.pageData[page].products === null) {
						continue;
					}
					getExtraProductData(this.pageData[page].products, this);
				}
			}
		},
		$route (to, frm) {
			// This code handles navigation between pages for the googleBot agent.
			// Bots will navigate by requesting the same url but updating the
			// page query parameter.
			if (googleBot) {
				if (to.path === frm.path) {
					if (to.query.page && (! frm.query.page || frm.query.page !== to.query.page)) {
						this.page = ~~to.query.page;
						this.loadPage(this.page);
						console.log('LOADING PAAAGEE MOOON');
					}
				}
			}
		},
		filteredProductCount (to) {
			if (this.onSearchPage) {
				this.setDisableAutoScroll();
			}
		},
		dropdownOpen (to) {
			if (to) {
				trackProductFilter('open_sort_by');
			}
		},
		filterCount (to, frm) {
			if (to > frm) {
				trackProductFilter('apply_filter_options');
			} else if (to < frm) {
				trackProductFilter('remove_filter_options');
			}
		},
		currentQuery (to) {
			this.updatedQuery(to);
		},
		url (to, frm) {
			if (to !== frm) {
				this.reset(...this.resetData);
			}
		},
		autoCorrectQuery (to, frm) {
			this.$emit('update:autoCorrectQuery', to);
			// Only trigger update of currentQuery if autoCorrect is missing
			if (! frm && ! this.currentQuery?.autoCorrect) {
				this.$store.dispatch('productFilters/updateCurrentQuery', { query: {...this.$route.query, autoCorrect: to} });
			}
		},
	},
};
</script>
