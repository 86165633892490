<template>
	<div id="main-header-wrapper" :style="stickyWrapper">
		<div ref="wrapper" class="sticky-scroll-header" :style="stickyHeader">
			<header ref="header" id="main-header" :class="{'christmas': isChristmas}">
				<div class="main-header-inner header-container d-table px-0">
					<div class="d-table-row">
						<v-touch @tripletap.prevent="openAdminHub" class="header-item logo">
							<router-link :to="indexPath" class="logo-link d-block" aria-label="Webhallen home">
								<ximg :src="convertToCdnUrl('/img/logos/wh_logo_white.svg')" ariaLabel="white logo" role="img" />
							</router-link>
						</v-touch>
						<div class="header-item search stretch-x">
							<quick-search ref="search" v-model="lockers.search" v-if="showSearchAndMenu" />
						</div>
						<div v-if="! prerender" class="header-item">
							<!--Medlemsdropdown-->
							<member-widget
								v-model="lockers.member"
								:class="{'christmas': isChristmas}"
								:dissmissLoginPrompt.sync="dissmissLoginPrompt"
							/>
						</div>
						<div v-if="showSearchAndMenu" class="header-item">
							<cart v-model="lockers.cart" :min-top="top" />
						</div>
						<div v-if="showSearchAndMenu" class="header-item hide-header-item">
							<dropdown check-id="hamburger-menu" class="d-inline-block static" v-model="menu" :keep="prerender">
								<div slot="label" class="icon-button-wrapper">
									<img class="icon-button" :src="convertToCdnUrl('/img/icons/hamburger_menu.svg')" alt="hamburger-menu" width="26" height="26" />
								</div>
								<label for="hamburger-menu" class="cover-view" style="background-color: rgba(0,0,0,.4)" />
								<div class="relative">
									<div class="header-dropdown-arrow hamburger-menu-dropdown-arrow" />
								</div>
								<hamburger-menu @close="menu = false" />
							</dropdown>
						</div>
						<div v-if="isEmployee" class="header-item hide-header-item">
							<dropdown check-id="admin-hamburger-menu" class="d-inline-block static" v-model="lockers.admin">
								<div slot="label" class="icon-button-wrapper">
									<img class="icon-button" :src="convertToCdnUrl('/img/icons/hamburger_menu.svg')" width="26" height="26" alt="hamburger menu" />
								</div>
								<label for="admin-hamburger-menu" class="cover-view" style="background-color: rgba(0,0,0,.4)" />
								<div class="relative" style="z-index: 102">
									<div class="header-dropdown-arrow hamburger-menu-dropdown-arrow" />
								</div>
								<admin-hamburger-menu @close="lockers.admin = false;" />
							</dropdown>
						</div>
					</div>
				</div>
			</header>
			<div class="header-border">
				<span class="gradient" />
			</div>
			<site-nav
				:dissmissLoginPrompt.sync="dissmissLoginPrompt"
				@loaded="onNavLoad"
			/>
		</div>
	</div>
</template>

<script>
import bus from 'eventbus';
import router from 'router';
import mq from 'mediaQuery';
import store from 'datastore';
import { prerender } from 'staticData';
import prerenderAPI from 'prerenderAPI';
import { convertToCdnUrl } from 'utils';
import { mapState, mapGetters } from 'vuex';
import localSessionStorage from 'localSessionStorage';

export default {
	store,
	name: 'site-header',
	data () {
		return {
			mq,
			bus,
			prerender,

			/**
			 * Scroll Logic
			 */
			distance: 0,
			scrollPos: 0,
			lockedPos: null,
			previousScrollPos: 0,

			stickyHeader: null,
			stickyWrapper: null,

			animate: false,
			isTopped: true, // Header has diffrent scroll behavior when at the top.
			hideHeader: false, // Show/hide the header inside the viewport.

			/**
			 * Lockers:
			 * If any locker evaluates true, the header stays locked, allowing mobile users to scroll down.
			 * (e.g scrolling down to see items futher down in the dropdown menu)
			 */
			lockers: {
				cart: false,
				menu: false,
				admin: false,
				member: false,
				search: false,
			},
			dissmissLoginPrompt: null,
		};
	},
	methods: {
		convertToCdnUrl,
		openAdminHub () {
			if (this.isEmployee) {
				this.$router.push('/admin/');
			}
		},
		onNavLoad () {
			prerenderAPI.onNavLoad();
			this.updateHeaderWrapper();
		},
		onScroll () {
			this.scrollPos = window.scrollY || 0;
			const deltaScroll = this.scrollPos - this.previousScrollPos;
			const direction = Math.sign(deltaScroll);

			if (this.scrollPos <= 0) {
				this.isTopped = true;
			}
			if (this.scrollPos >= this.wrapperHeight) {
				this.isTopped = false;
			}

			if (this.lockedPos !== null) {
				this.lockedPos = Math.min(this.lockedPos, this.scrollPos);
			}

			// User changed scroll direction.
			if (Math.sign(this.distance) !== direction || direction === 0) {
				this.distance = 0;
			}

			this.distance += deltaScroll;

			const scrollThreshold = 64;
			if (Math.abs(this.distance) > scrollThreshold) {
				// User has scrolled upwards.
				if (direction < 0) {
					this.hideHeader = false;
				}
				// User has scrolling downwards.
				if (direction > 0) {
					this.hideHeader = true;
				}
			}

			this.previousScrollPos = this.scrollPos;
			this.updateHeaderWrapper();
		},
		updateHeaderWrapper () {
			if (this.lockedPos === null) {
				this.stickyWrapper = { minHeight: document.body.offsetHeight + 'px' };
				this.stickyHeader = { top: -this.wrapperHeight * this.hideHeader + 'px' };
			} else {
				this.stickyWrapper = { minHeight: this.lockedPos + this.wrapperHeight + 'px' };
				this.stickyHeader = { top: '0px' };
			}

			if (! this.isTopped) {
				if (this.animate) {
					this.stickyHeader.transition = 'top 200ms ease-in 0.5ms';
				}
				this.animate = true;
			} else {
				this.stickyHeader.position = 'absolute';
				this.stickyHeader.top = '0px';
				this.animate = false;
			}
		},
	},
	mounted () {
		window.addEventListener('scroll', this.onScroll);
		bus.$on('header.hide', () => { this.hideHeader = true; });
		bus.$on('header.show', () => { this.hideHeader = false; });
		bus.$on('update:siteHeight', this.updateHeaderWrapper);

		this.dissmissLoginPrompt = localSessionStorage.get('dissmissLoginPrompt') ?? false;
	},
	beforeDestroy () {
		window.removeEventListener('scroll', this.onScroll);
	},
	watch: {
		burgerOpen (isOpen) {
			this.lockers.menu = isOpen;
		},
		$route (to, from) {
			// Ny sida! Visa den fina headern igen! :D
			if (to.name !== from.name || to.params !== from.params) {
				this.hideHeader = false;
				this.updateHeaderWrapper();
			}
		},
		isLocked (locked) {
			if (locked) {
				this.lockedPos = this.scrollPos;
			} else {
				this.lockedPos = null;
			}
			this.animate = false;
		},
		hideHeader () {
			bus.$emit('header.status', [this.hideHeader, this.wrapperHeight]);
		},
		dissmissLoginPrompt (to) {
			localSessionStorage.set('dissmissLoginPrompt', to);
		},
	},
	computed: {
		menu: {
			get () {
				return this.burgerOpen;
			},
			set (open) {
				// this will activate the burgerOpen watcher.
				store.commit('navStatus/burgerOpen', open);
			},
		},
		indexPath () {
			return router.route({ name: 'index' });
		},
		isLocked () {
			return Object.values(this.lockers).some(l => l === true);
		},
		top () {
			if (! this.isLocked) {
				return false;
			}
			if (! this.isTopped) {
				return -this.scrollPos;
			}
			return this.scrollPos;
		},
		showSearchAndMenu () {
			return ! prerender && this.$route.name !== 'checkout';
		},
		...mapGetters({
			isEmployee: 'user/isEmployee',
		}),
		...mapState({
			burgerOpen: state => state.navStatus.menu.burgerOpen,
		}),
		wrapperHeight () {
			const wrapper = this.$refs.wrapper;
			let height = wrapper ? wrapper.clientHeight : 0;

			// on mobile we got to add the searchbar's height aswell.
			if (this.mq.current <= this.mq.sm && this.$refs.search) {
				const search = this.$refs.search.$el;
				height += search ? search.clientHeight : 0;
			}

			return height;
		},
		isChristmas () {
			const date = new Date();
			return date.getMonth() === 11 ? true : date.getMonth() === 0 && date.getDate() < 11 ? true : false;
		},
	},
};
</script>
