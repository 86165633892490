<template>
	<div id="product-list-filter-container" :class="{ 'product-list-filter-wrapper': unhooked && open }" v-show="filterEnabled">
		<div class="filter-list-header" v-if="! unhooked">
			<img :src="filterIconUrl" alt="filter icon">
			<h2>{{ ts('FILTER') }}</h2>
		</div>
		<div class="product-list-filters-bg" @click="closeMenu" :class="{ fade }" v-if="unhooked && open" />
		<div class="product-list-filters" :class="{ unhooked }" :style="style">
			<div class="filter-list-header" v-if="unhooked">
				<img :src="filterIconUrl">
				<h2 class="level-one-heading">{{ ts('FILTER') }}</h2>
			</div>
			<div class="filter-contents">
				<text-button
					v-if="hasFilters"
					variant="destructive"
					width="stretch"
					text="Rensa filter"
					@click="clearFilters()"
				/>
				<filter-category
					v-if="shouldShowSearchTree"
					:category="searchTree"
					:categoryFilterMap="categoryFilterMap"
					:showSubCategories="showSubCategories"
					@updateSearchTree="updateSearchTree"
					@updateSearchCategory="updateSearchCategory"
				/>

				<!-- @TODO add text search in categories etc, part 2 of voyado -->
				<!-- Product name -->
				<!-- <div class="filter-box" v-if="panels.name && ! useLoop54Filters"> -->
					<!-- <div class="header-toggle" @click="show.name = ! show.name">
						<a class="pull-right glyphicon toggle-icon" :class="show.name ? 'glyphicon-minus' : 'glyphicon-plus'"/>
						<h3>{{ ts('FILTER_TYPE_TEXT') }}</h3>
					</div> -->
					<!-- <div v-if="show.name">
						<input-text v-model="productName" placeholder="Filtrera produktnamn..." />
					</div> -->
					<!-- <hr />
				</div> -->

				<filter-box
					v-for="attribute in attributes"
					:attribute="attribute"
					:attributeFilterMap="attributeFilterMap"
					:storeValues="attribute.stock ? stores : null"
					:enabledFilters="enabledFilters"
					:key="attribute.id"
					:isDesktop="isDesktop"
					@updateFilters="updateFilters"
				/>
				<div
					v-if="attributes && ! attributes.length"
					class="alert alert-danger mt-4"
				>
					Inga filter tillgängliga för denna sökning
				</div>
				<template v-if="unhooked">
					<div class="clearfix" />
					<div class="filter-result">
						<progress-bar class="progress-result-counter mb-0" :value="filteredProductCount / totalProductCount" :text="(e) => 'Visar ' + formatNumber(filteredProductCount) + ' av ' + ts('RESULT_COUNT', formatNumber(totalProductCount))"></progress-bar>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import { isEqual, debounce } from 'lodash';
import bus from 'eventbus';
import mq from 'mediaQuery';
import store from 'datastore';
import { mapState } from 'vuex';
import { convertToCdnUrl, formatNumber } from 'utils';
import { t, getStore } from 'datastore/i18n';

export default {
	store,
	data () {
		return {
			mq,
			// @TODO fritext sök voyado steg 2
			// productName: '',
			unhooked: false,
			open: false,
			fade: true,
			originalParent: null,
		};
	},
	beforeMount () {
		bus.$on('supersearch.open', this.openMenu);
		bus.$on('supersearch.close', this.closeMenu);
	},
	beforeDestroy () {
		bus.$off('supersearch.open', this.openMenu);
		bus.$off('supersearch.close', this.closeMenu);
	},
	beforeRouteUpdate (to, frm, next) {
		if (to && frm && to.name === frm.name) {
			next();
		}
	},
	mounted () {
		this.originalParent = this.$el.parentElement;
		if (mq.current < mq.lg) {
			this.unhook();
		} else {
			this.hook();
		}
	},
	methods: {
		convertToCdnUrl,
		formatNumber,
		ts: t.prefix('SUPERSEARCH'),
		openMenu () {
			this.fade = true;
			this.open = this.unhooked;
			setTimeout(() => {
				this.fade = false;
			}, 50);
		},
		closeMenu () {
			this.fade = true;
			this.open = false;
		},
		hook () {
			this.originalParent.appendChild(this.$el);
			this.unhooked = false;
		},
		unhook () {
			document.body.appendChild(this.$el);
			this.unhooked = true;
		},
		updateFilters (enabledFilters) {
			if (enabledFilters !== this.enabledFilters) {
				this.$store.commit('productFilters/setEnabledFilters', enabledFilters);
			}
		},
		// This function updates the actual url in the browser
		updateUrlQuery () {
			const urlQuery = JSON.parse(JSON.stringify(this.$route.query || {}));

			if (this.sortingOption) {
				urlQuery.sortBy = this.sortingOption;
			} else {
				delete urlQuery.sortBy;
			}

			const filters = this.enabledFilters;
			if (filters.length) {
				urlQuery.f = filters.map(f => (f.prioritized ? '*' : '') + f.type + '^' + f.value);
			} else {
				delete urlQuery.f;
			}

			if (this.$route.query.page) {
				urlQuery.page = this.$route.query.page;
			} else {
				delete urlQuery.page;
			}

			if (urlQuery.f && ! urlQuery.f.length) {
				// If the filter object is empty in the end, then get rid of it.
				delete urlQuery.f;
			}

			// Skip the dispatch if the nex query is equal to the old one
			if (! isEqual(this.$route.query, urlQuery)) {
				this.$store.dispatch('productFilters/updateCurrentQuery', { query: urlQuery });
			}

			this.$router.setQuery(urlQuery);
			// Bypass old filter queries
			if (urlQuery?.f && (! urlQuery.f.find(value => /[-]/.test(value)) || urlQuery.f.find(value => value == 'stock') || urlQuery.f.find(value => /\^+[\d][\d]+\-?[\d]+/.test(value)) || urlQuery.f.find(value => /^\d+/.test(value)))) {
				delete urlQuery.f;
				this.$store.dispatch('productFilters/updateSearchTree', { currentId: 0 });
				this.$store.commit('productFilters/setEnabledFilters', []);
			}
		},
		clearFilters () {
			this.$store.dispatch('productFilters/updateSearchTree', { currentId: 0 });
			this.$store.commit('productFilters/setEnabledFilters', []);
		},
		elementIsVisible () {
			if (this.$el.offsetParent) {
				return true;
			}
			return this.unhooked;
		},
		updateSearchTree (id = 0, keep = false) {
			if (this.searchTree.current.id !== id) {
				let updatedEnabledFilters = this.enabledFilters;
				if (id === 0) {
					updatedEnabledFilters = updatedEnabledFilters.filter(object => {
						return object.type !== 'category-filter';
					});
				} else {
					updatedEnabledFilters = [
						...this.enabledFilters,
						{
							type: 'category-filter', // instead of category to not be confused with category pages
							value: 'category-1-' + id,
							prioritized: false,
						},
					];
				}
				this.$store.commit('productFilters/setEnabledFilters', updatedEnabledFilters);
				this.$store.dispatch('productFilters/updateSearchTree', { currentId: id });
			}
		},
		updateSearchCategory (id, enabled) {
			let updatedEnabledFilters = this.enabledFilters;
			const prioritizedCategories = this.enabledFilters.find(filter => filter.prioritized);
			let activeCheckboxes = [];
			if (prioritizedCategories) {
				activeCheckboxes = prioritizedCategories.value.split('-').pop().split('~');
			}

			for (const i in updatedEnabledFilters) {
				if (updatedEnabledFilters[i].prioritized) {
					updatedEnabledFilters.splice(i, 1);
				}
			}

			for (const i in activeCheckboxes) {
				if (activeCheckboxes[i] == id) {
					activeCheckboxes.splice(i, 1);
				}
			}

			if (enabled) {
				activeCheckboxes.push(id);
			}

			if (activeCheckboxes.length) {
				updatedEnabledFilters = [
					...this.enabledFilters,
					{
						type: 'category-filter', // instead of category to not be confused with category pages
						value: 'category-1-' + activeCheckboxes.join('~'),
						prioritized: true,
					},
				];
			}
			this.$store.commit('productFilters/setEnabledFilters', updatedEnabledFilters);
		},
	},
	computed: {
		useLoop54Filters () {
			return this.$route.name === 'search';
		},
		filterIconUrl () {
			// @DARKMODE TODO
			// const isDark = this.$store.getters['user/isDark'];
			// return convertToCdnUrl('/api/dynimg/icon/list_filter/' + (isDark ? 'FFFFFF' : '1A1A1D'));
			return convertToCdnUrl('/api/dynimg/icon/list_filter/1A1A1D');
		},
 		shouldShowSearchTree () {
			if (this.searchTree) {
				const tree = this.searchTree.tree;
				if (tree && tree.children && tree.children.length <= 1) {
					// Visa inte kategorifilter om det bara finns en kategori att välja
					return false;
				}

				return (this.$route.name === 'search' ||
					this.isMemberOffersPage ||
					this.isCampaignPage ||
					this.isTopListPage
					);
			} else {
				return null;
			}
		},
		showSubCategories () {
			return ! this.isCampaignPage && ! this.isMemberOffersPage;
		},
		style () {
			if (! this.unhooked) {
				return null;
			}
			return {
				left: this.open ? 0 : '-100%',
			};
		},
		debouncedUpdateQuery () {
			return debounce(this.updateUrlQuery, 250);
		},
		hasFilters () {
			return this.enabledFilters.length;
		},
		attributeFilterMap () {
			const map = {};
			let parts;
			if (this.attributes) {
				for (const attribute of this.attributes) {
					map[attribute.id] = {};
					for (const i in attribute.values) {
						map[attribute.id][attribute.values[i].value] = false;
					}
				}
				for (const filter of this.enabledFilters) {
					if (filter.type === 'attributes') {
						parts = filter.value.split('-');
						const id = parts[0];
						parts.splice(0, 2);
						parts = parts.join('-').split('~');
						for (const part of parts) {
							if (map[id][part] !== undefined) {
								map[id][part] = true;
							}
						}
					}
				}
			}
			return map;
		},
		categoryFilterMap () {
			const map = {};
			if (this.searchTree?.current && this.searchTree?.current.children) {
				for (const category of this.searchTree.current.children) {
					map[category.id] = false;
				}

				const prioritizedCategories = this.enabledFilters.find(filter => filter.prioritized);
				if (prioritizedCategories) {
					const activeCheckboxes = prioritizedCategories.value.split('-').pop().split('~');
					for (const filter of activeCheckboxes) {
						map[filter] = true;
					}
				}
			}
			return map;
		},
		stores () {
			const stores = [];

			for (let i = 0; i < this.userSettings.myStores.length; ++i) {
				stores.push(this.userSettings.myStores[i]);
			}

			for (let i = 0; i < this.user.storeSuggestions.length; ++i) {
				const store = this.user.storeSuggestions[i];
				if (! stores.includes(store)) {
					stores.push(store);
				}
			}

			for (let i = 0; i < this.allStoresList.length; ++i) {
				const store = this.allStoresList[i];
				if (! stores.includes(store)) {
					stores.push(store);
				}
			}

			const returnStores = stores.map(store => getStore(store)).filter(store => !! store);
			returnStores.unshift({
				id: 'web',
				name: 'Webblager', // @TODO translate
			});
			return returnStores;
		},
		allStoresList () {
			const stores = [];
			for (const group of this.userSettings.storeGroupFormat) {
				for (const store of group.stores) {
					if (isNaN(store)) {
						continue;
					}
					stores.push(store);
				}
			}
			return stores;
		},
		isCampaignPage () {
			return this.$route.name === 'campaign';
		},
		isTopListPage () {
			return this.$route.name === 'toplist';
		},
		isMemberOffersPage () {
			return this.$route.name === 'member.offers';
		},
		isManufacturerPage () {
			return this.isManufacturerCategoryPage || this.$route.name === 'manufacturer';
		},
		isManufacturerCategoryPage() {
			return this.$route.name === 'manufacturer-page';
		},
		isDesktop () {
			return mq.current > mq.md;
		},
		...mapState({
			user: state => state.user,
			section: state => state.navStatus.section,
			userSettings: state => state.userSettings,
			attributes: state => state.productFilters.attributes,
			enabledFilters: state => state.productFilters.enabledFilters,
			filterEnabled: state => state.productFilters.filterEnabled,
			initialFilters: state => state.productFilters.initialFilters,
			currentQuery: state => state.productFilters.currentQuery,
			sortingOption: state => state.productFilters.sortingOption,
			searchTree: state => state.productFilters.searchTree,
			filteredProductCount: state => state.productFilters.filteredProductCount,
			totalProductCount: state => state.productFilters.totalProductCount,
		}),
	},
	watch: {
		'mq.current' (size) {
			if (size < mq.lg) {
				this.unhook();
			} else {
				this.hook();
			}
		},
		enabledFilters (to, frm) {
			this.debouncedUpdateQuery();
		},
		sortingOption (to, frm) {
			this.updateUrlQuery();
		},
		attributeFilterMap: {
			handler(to, frm) {
				if (! isEqual(to, frm)) {
					this.$store.dispatch('productFilters/updateAttributeShow', { settings: null });
				}
			},
			deep: true,
		},
	},
};
</script>
